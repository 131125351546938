<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="5"
      item-per
      class="elevation-2 rounded row-pointer"
      :footer-props="{
        'items-per-page-text': 'จำนวนแถว',
      }"
      no-data-text="ไม่มีข้อมูล"
      :loading="isLoading"
      @click:row="clickMaintenance"
    ></v-data-table>
    <EditMaintenanceContractPriceDialog
      v-model="editMaintenanceContractPriceDialog"
      :maintenanceInfo="maintenanceInfo"
      readonly
      :isAdd="isAdd"
      @close="editMaintenanceContractPriceDialog = false"
    />
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  components: {
    EditMaintenanceContractPriceDialog: () =>
      import(
        '@/components/maintenance/dialog/EditMaintenanceContractPriceDialog.vue'
      )
  },
  data: () => ({
    headers: [
      {
        text: 'ปีงบประมาณ',
        align: 'center',
        sortable: false,
        value: 'budget_year',
        class: 'primary rounded-tl white--text'
      },
      {
        text: 'เลขที่ใบสั่ง',
        align: 'center',
        sortable: false,
        value: 'quotation_no',
        class: 'primary white--text'
      },
      {
        text: 'งาน',
        align: 'center',
        sortable: false,
        value: 'task',
        class: 'primary white--text'
      },
      {
        text: 'ตอนควบคุม',
        sortable: false,
        value: 'section_part_id',
        class: 'primary white--text'
      },
      {
        text: 'ตำแหน่ง',
        sortable: false,
        value: 'position',
        class: 'primary white--text'
      },
      {
        text: 'ชื่อสะพาน',
        sortable: false,
        value: 'bridge_name',
        class: 'primary white--text rounded-tr'
      }
    ],
    items: [],
    isLoading: false,
    isAdd: false,
    itemLists: {},
    getKm: utils.getKm,
    editMaintenanceContractPriceDialog: false,
    maintenanceInfo: {}
  }),
  async mounted () {
    await this.getBridgeList()
    this.getMaintenanceContractPriceList({ bridge_id: this.$route.query.id })
  },
  methods: {
    async getBridgeList () {
      const bridgeList = await this.$store.dispatch('getBridgeList', {})
      this.itemLists.bridgeList = []
      bridgeList.forEach((section) => {
        this.itemLists.bridgeList.push(...section.items)
      })
    },
    clickMaintenance (maintenance) {
      this.isAdd = false
      this.maintenanceInfo = maintenance
      this.editMaintenanceContractPriceDialog = true
    },
    async getMaintenanceContractPriceList (filter) {
      this.isLoading = true
      this.filterMaintenanceContractPriceDialog = false
      const items = await this.$store.dispatch(
        'maintenance/getMaintenanceContractPriceList',
        { filter }
      )
      for (const item of items) {
        item.budget_year = parseInt(item.budget_year) + 543
        item.bridge_id = parseInt(item.bridge_id)
        const bridgeName = this.itemLists.bridgeList.find(
          (bridge) => bridge.id === item.bridge_id
        )
        item.bridge_name = `${bridgeName.name} ${
          bridgeName.direction ? `(${bridgeName.direction})` : ''
        }`
        item.position = `${this.getKm(item.km_start)} + ${this.getKm(
          item.km_end
        )} ${item.ref_direction_id ? 'LT' : 'RT'}`
      }
      this.items = items
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
